.invoice {
  /* Centered text */
  .text-center {
    text-align: center;
  }

  /* Text for descriptions with stronger emphasis */
  .feature-description {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }

  /* Styling for the 'how it works' section */
  .how-it-works {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;

    .how-it-works-list {
      list-style-type: disc;
      padding-left: 0;
      text-align: center; /* Centers the bullets and text */
    }
  }

  /* General description styling */
  .general-description {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }

  /* Call to action button styling */
  .call-to-action {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 2rem;
    font-weight: bold;
  }

  /* Styling for the invoice organization */
  .invoice-organization {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 2rem;
  }

  /* Adding responsiveness for smaller screens */
  @media (max-width: 768px) {
    .container {
      max-width: 100%;
    }

    .feature-description,
    .how-it-works,
    .general-description,
    .call-to-action,
    .invoice-organization {
      font-size: 1rem;
    }
  }
}
